export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'en',
  messages: {
    en: {
      menu: {
        dashboard: 'Dashboard',
        policies: 'Policies',
        claim: 'Claim',
        claims: 'Claims',
        help: 'Help'
      },
      dashboard: {title: 'Dashboard'},
      insurance: {title: 'Insurance'},
      policies: {title: 'Policies'},
      claim: {title: 'Claim'},
      claims: {title: 'Claims'},
      help: {title: 'Help'},
      settings: { title: 'Settings' },
      newClaim: { title: 'New Claim' },
    },
    fr: {
      menu: {
        dashboard: 'Sommaire',
        policies: 'Assurance',
        claim: 'Réclamation',
        claims: 'Réclamations',
        help: 'Aide',
      },
      dashboard: { title: 'Sommaire' },
      insurance: { title: 'Assurance' },
      policies: { title: 'Assurance' },
      claim: { title: 'Réclamation' },
      claims: { title: 'Réclamations' },
      help: { title: 'Aide' },
      settings: { title: 'Paramètres' },
      newClaim: { title: 'Nouvelle réclamation' },
    }
  }
}))
